import { Ref } from "vue";
import { FormItemRule } from "naive-ui";
import {
  minMaxLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const productionProcessExplanationVerificationRules = (
  field: string,
  isDirty: Ref<boolean>,
  required = false
): FormItemRule[] => {
  return [
    {
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!isDirty.value || (!required && !value?.length)) {
            resolve();
            return;
          }
          if (required && !value?.length) {
            reject(Error(requiredMessage(field)));
          }
          if (value.length < 3 || value.length > 2000) {
            reject(Error(minMaxLengthMessage(field, 3, 2000)));
          } else {
            resolve();
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default productionProcessExplanationVerificationRules;
