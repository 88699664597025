export enum MTKDataKeysEnum {
  BIGGEST_HOLE = "biggestHole",
  SMALLEST_HOLE = "smallestHole",
  NUMBER_OF_HOLES = "numberOfHoles",
  NUMBER_OF_FEATURES = "numberOfFeatures",
  BOSS_FEATURES_COUNT = "bossFeaturesCount",
  FACE_FEATURES_COUNT = "faceFeaturesCount",
  NUMBER_OF_UNIQ_HOLES = "numberOfUniqHoles",
  BOSS_INSTANCES_COUNT = "bossInstancesCount",
  FACE_INSTANCES_COUNT = "faceInstancesCount",
  POCKET_FEATURES_COUNT = "pocketFeaturesCount",
  TOTAL_INSTANCES_COUNT = "totalInstancesCount",
  NUMBER_OF_UNIQ_FEATURES = "numberOfUniqFeatures",
  POCKET_INSTANCES_COUNT = "pocketInstancesCount",
  NUMBER_OF_MILLING_FEATURES = "numberOfMillingFeatures",
  NUMBER_OF_TURNING_FEATURES = "numberOfTurningFeatures",
  TURNING_FACE_FEATURES_COUNT = "turningFaceFeaturesCount",
  TURNING_FACE_INSTANCES_COUNT = "turningFaceInstancesCount",
}
