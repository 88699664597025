const similarPartProperties = [
  {
    key: "material",
    value: "",
  },
  {
    key: "surfaceProcesses",
    value: "",
  },
  {
    key: "complexityCode",
    value: "",
  },
  {
    key: "partDimensions",
    value: "",
  },
  {
    key: "partNetWeight",
    value: "",
  },
];

export default similarPartProperties;
