const allSimilarPartProperties = [
  {
    key: "materialGroup",
    title: "Material group",
  },
  {
    key: "surfaceProcesses",
    title: "Surface treatment",
  },
  {
    key: "complexityCode",
    title: "Complexity",
  },
  {
    key: "partDimensions",
    title: "Dimensions / CTQ ",
  },
  {
    key: "partNetWeight",
    title: "Part net weight",
  },
  {
    key: "subTech",
    title: "Sub tech",
  },
  {
    key: "shape",
    title: "Shape",
  },
];

export default allSimilarPartProperties;
