import { Feature, ModelEntityInterface } from "@/types/ModelEntity";
import { MetadataDataKeysEnum } from "@/enums/costing/similar-parts/MetadataDataKeysEnum";

export default () => {
  const calculateHoles = (
    holes: Feature[]
  ): {
    maxRadius: number;
    minRadius: number;
    uniqHolesCount: number;
    totalHolesCount: number;
  } => {
    let maxRadius = 0;
    let minRadius = Infinity;
    let totalHolesCount = 0;

    holes.forEach((hole: Feature) => {
      const { radius, instances } = hole;
      if (radius && radius > maxRadius) {
        maxRadius = radius;
      }
      if (radius && radius < minRadius) {
        minRadius = radius;
      }
      if (instances?.length) {
        totalHolesCount += instances.length;
      }
    });

    return {
      maxRadius,
      minRadius: minRadius === Infinity ? 0 : minRadius,
      uniqHolesCount: holes.length,
      totalHolesCount,
    };
  };

  const calculateFeaturesCount = (
    items: Feature[]
  ): { featuresCount: number; instancesCount: number } => {
    let instancesCount = 0;

    items.forEach((item) => {
      if (item?.instances?.length) {
        instancesCount += item.instances.length;
      }
    });

    return {
      featuresCount: items.length || 0,
      instancesCount,
    };
  };

  const getParamsFromMetadata = (row: ModelEntityInterface) => {
    const defaultRow = {};

    if (!row.metadata) {
      return defaultRow;
    }

    try {
      if (row?.metadata?.solids) {
        Object.values(row.metadata.solids).forEach((value) => {
          const features = value.features;
          if (!features || Object.keys(features).length === 0) {
            return defaultRow;
          }
          const uniqFeatures = Object.values(features).length || 0;
          // temporarily hidden, will be open on the second Stage
          // const uniqFeaturesTitles = Object.keys(features).join(", ") || "";
          const holeData = features.Hole
            ? calculateHoles(features.Hole)
            : calculateHoles([]);
          const holeFeaturesData = features.Hole
            ? calculateFeaturesCount(features.Hole)
            : calculateFeaturesCount([]);
          const bossFeaturesData = features.Boss
            ? calculateFeaturesCount(features.Boss)
            : calculateFeaturesCount([]);
          const faceFeaturesData = features.Face
            ? calculateFeaturesCount(features.Face)
            : calculateFeaturesCount([]);
          const pocketFeaturesData = features.Pocket
            ? calculateFeaturesCount(features.Pocket)
            : calculateFeaturesCount([]);
          const turningFaceFeaturesData = features.TurningFace
            ? calculateFeaturesCount(features.TurningFace)
            : calculateFeaturesCount([]);
          const totalFeaturesCount =
            holeFeaturesData.featuresCount +
            bossFeaturesData.featuresCount +
            faceFeaturesData.featuresCount +
            pocketFeaturesData.featuresCount +
            turningFaceFeaturesData.featuresCount;

          const totalInstancesCount =
            holeFeaturesData.instancesCount +
            bossFeaturesData.instancesCount +
            faceFeaturesData.instancesCount +
            pocketFeaturesData.instancesCount +
            turningFaceFeaturesData.instancesCount;

          Object.assign(defaultRow, {
            // holes
            biggestHole: holeData.maxRadius,
            smallestHole: holeData.minRadius,
            numberOfUniqHoles: holeData.uniqHolesCount,
            numberOfHoles: holeData.totalHolesCount,
            // boss
            bossFeaturesCount: bossFeaturesData.featuresCount,
            bossInstancesCount: bossFeaturesData.instancesCount,
            // face
            faceFeaturesCount: faceFeaturesData.featuresCount,
            faceInstancesCount: faceFeaturesData.instancesCount,
            // pocket
            pocketFeaturesCount: pocketFeaturesData.featuresCount,
            pocketInstancesCount: pocketFeaturesData.instancesCount,
            // turningFace - temporarily hidden, will be open on the second Stage
            // turningFaceFeaturesCount: turningFaceFeaturesData.featuresCount,
            // turningFaceInstancesCount: turningFaceFeaturesData.instancesCount,
            // totals
            totalInstancesCount,

            numberOfUniqFeatures: uniqFeatures,
            // temporarily hidden, will be open on the second Stage
            // uniqFeaturesTitles,
            numberOfFeatures: totalFeaturesCount,
            numberOfTurningFeatures: 0,
            numberOfMillingFeatures: 0,
          });
        });
      }

      const summary = row?.metadata?.summary;
      if (summary) {
        const metadataParams: Record<string, number> = {};

        const keyMappings = [
          {
            source: () =>
              summary.faceCounts &&
              Object.values(summary.faceCounts).reduce(
                (sum, value) => sum + value,
                0
              ),
            target: MetadataDataKeysEnum.SUMMARY_FACE_COUNTS,
          },
          {
            source: "areaToVolumeRatio",
            target: MetadataDataKeysEnum.SUMMARY_AREA_TO_VOLUME_RATIO,
          },
          {
            source: "cylindricalRadiuses.max",
            target: MetadataDataKeysEnum.SUMMARY_CYLINDRICAL_RADIUSES_MAX,
          },
          {
            source: "cylindricalRadiuses.min",
            target: MetadataDataKeysEnum.SUMMARY_CYLINDRICAL_RADIUSES_MIN,
          },
          {
            source: "cylindricalRadiuses.count",
            target: MetadataDataKeysEnum.SUMMARY_CYLINDRICAL_RADIUSES_COUNT,
          },
          {
            source: "uniqueFlatDirections",
            target: MetadataDataKeysEnum.SUMMARY_UNIQUE_FLAT_DIRECTIONS,
          },
          {
            source: "uniqueRotationAxisCount",
            target: MetadataDataKeysEnum.SUMMARY_UNIQUE_ROTATION_AXIS_COUNT,
          },
          {
            source: "uniqueCylyndricalDirectionsCount",
            target:
              MetadataDataKeysEnum.SUMMARY_UNIQUE_CYLINDRICAL_DIRECTIONS_COUNT,
          },
        ];

        keyMappings.forEach(({ source, target }) => {
          const value =
            typeof source === "function"
              ? source()
              : getNestedValue(summary, source);
          if (value !== undefined) {
            metadataParams[target] = value;
          }
        });
        Object.assign(defaultRow, metadataParams);
      }
    } catch (error) {
      console.error("Error parsing metadata:", error);
    }

    return defaultRow;
  };

  return { getParamsFromMetadata };
};

const getNestedValue = (obj: any, path: string): number | undefined => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};
