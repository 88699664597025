import { SimilarPartsParametersDefinition } from "@/types/admin-parameters/SimilarPartsParametersInterface";

export const similarPartsParameterLabels: Record<
  keyof SimilarPartsParametersDefinition,
  string
> = {
  complexityCode: "Complexity code",
  chargeableWeight: "Chargeable weight",
  materialWeight: "Material weight",
  partNetWeight: "Part net weight",
  partDimensions: "Part dimensions",
  internalCtqDimensions: "Internal CTQ dimensions",
  width: "Width",
  height: "Height",
  length: "Length",
  subTech: "Sub tech",
  roundBarDiameter: "Round bar diameter",
  roundBarLength: "Round bar length",
  surfaceProcessCount: "Surface process count",
  buyItemCost: "Buy item cost",
  buyItemQuantity: "Buy item quantity",
  heatTreatmentsCount: "Heat treatments count",
  heatTreatmentNames: "Heat treatment names",
  numberOfUniqFeatures: "Number of unique features",
  numberOfUniqHoles: "Number of unique holes",
  numberOfHoles: "Number of holes",
  biggestHole: "Biggest hole",
  smallestHole: "Smallest hole",
  numberOfFeatures: "Number of features",
  numberOfTurningFeatures: "Number of turning features",
  numberOfMillingFeatures: "Number of milling features",
  bossFeaturesCount: "Boss features count",
  bossInstancesCount: "Boss instances count",
  faceFeaturesCount: "Face features count",
  faceInstancesCount: "Face instances count",
  pocketFeaturesCount: "Pocket features count",
  pocketInstancesCount: "Pocket instances count",
  turningFaceFeaturesCount: "Turning face features count",
  turningFaceInstancesCount: "Turning face instances count",
  totalInstancesCount: "Total instances count",
  summaryFaceCounts: "Face counts",
  summaryAreaToVolumeRatio: "Area to volume ratio",
  summaryCylindricalRadiusesMax: "Maximum cylindrical radius",
  summaryCylindricalRadiusesMin: "Minimum cylindrical radius",
  summaryCylindricalRadiusesCount: "Cylindrical radiuses count",
  summaryUniqueFlatDirections: "Unique flat directions",
  summaryUniqueRotationAxisCount: "Unique rotation axis count",
  summaryUniqueCylindricalDirectionsCount:
    "Unique cylindrical directions count",
};
