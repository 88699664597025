export enum MetadataDataKeysEnum {
  SUMMARY_FACE_COUNTS = "summaryFaceCounts",
  SUMMARY_AREA_TO_VOLUME_RATIO = "summaryAreaToVolumeRatio",
  SUMMARY_CYLINDRICAL_RADIUSES_MAX = "summaryCylindricalRadiusesMax",
  SUMMARY_CYLINDRICAL_RADIUSES_MIN = "summaryCylindricalRadiusesMin",
  SUMMARY_CYLINDRICAL_RADIUSES_COUNT = "summaryCylindricalRadiusesCount",
  SUMMARY_UNIQUE_FLAT_DIRECTIONS = "summaryUniqueFlatDirections",
  SUMMARY_UNIQUE_ROTATION_AXIS_COUNT = "summaryUniqueRotationAxisCount",
  SUMMARY_UNIQUE_CYLINDRICAL_DIRECTIONS_COUNT = "summaryUniqueCylindricalDirectionsCount",
}
