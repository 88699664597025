export enum CostingDataKeysEnum {
  COMPLEXITY_CODE = "complexityCode",
  CHARGEABLE_WEIGHT = "chargeableWeight",
  MATERIAL_WEIGHT = "materialWeight",
  PART_NET_WEIGHT = "partNetWeight",
  PART_DIMENSIONS = "partDimensions",
  INTERNAL_CTQ_DIMENSIONS = "internalCtqDimensions",
  WIDTH = "width",
  HEIGHT = "height",
  LENGTH = "length",
  SUB_TECH = "subTech",
  ROUND_BAR_DIAMETER = "roundBarDiameter",
  ROUND_BAR_LENGTH = "roundBarLength",
  SURFACE_PROCESS_COUNT = "surfaceProcessCount",
  BUY_ITEM_COST = "buyItemCost",
  BUY_ITEM_QUANTITY = "buyItemQuantity",
  HEAT_TREATMENTS_COUNT = "heatTreatmentsCount",
  HEAT_TREATMENT_NAMES = "heatTreatmentNames",
}
