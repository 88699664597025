import cadex from "@cadexchanger/web-toolkit";

class CancellationObserver extends cadex.Base_ProgressStatusObserver {
  /**
   * @param {XMLHttpRequest} xhr
   */
  constructor(xhr) {
    super();
    this.xhr = xhr;
  }

  /**
   * @override
   */
  changedValue() {
    /**/
  }

  /**
   * @override
   */
  completed() {
    /**/
  }

  /**
   * @override
   */
  canceled() {
    this.xhr.abort();
  }
}

/**
 * Remote file data provider.
 * @param {string} theUrl
 * @param {cadex.Base_ProgressScope} theProgressScope
 * @returns {Promise<ArrayBuffer>}
 */
export const fetchFile = async (theUrl, theProgressScope) => {
  const aFileDownloadingScope = new cadex.Base_ProgressScope(theProgressScope);
  /** @type {CancellationObserver|undefined} */
  let aProgressStatusCancellationObserver;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "arraybuffer";
    xhr.open("GET", theUrl, true);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };
    xhr.onprogress = (event) => {
      aFileDownloadingScope.increment(
        event.loaded - aFileDownloadingScope.value
      );
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED && xhr.status === 200) {
        const fileSize = xhr.getResponseHeader("content-length");
        aFileDownloadingScope.setRange(0, Number(fileSize));
      }
    };

    aProgressStatusCancellationObserver = new CancellationObserver(xhr);
    aFileDownloadingScope.owner.register(aProgressStatusCancellationObserver);

    xhr.send();
  }).finally(() => {
    aFileDownloadingScope.close();
    if (aProgressStatusCancellationObserver) {
      aFileDownloadingScope.owner.unregister(
        aProgressStatusCancellationObserver
      );
    }
  });
};

export class ModelAnalyzer extends cadex.ModelData_SceneGraphElementVoidVisitor {
  constructor() {
    super();
    this.hasBRepRep = false;
    this.polyRepCount = 0;
  }

  /**
   * @param {cadex.ModelData_Part} thePart
   */
  visitPart(thePart) {
    const aHasBRepRep = Boolean(thePart.brepRepresentation());
    if (aHasBRepRep) {
      this.hasBRepRep = true;
    }
    this.polyRepCount = Math.max(
      this.polyRepCount,
      thePart.numberOfRepresentation - (aHasBRepRep ? 1 : 0)
    );
  }
}

export class CustomSGEVisitor extends cadex.ModelData_SceneGraphElementVisitor {
  /**
   * @param theRepMask Defines a mask to filter part representations. Filter any representation by default.
   */
  constructor(theRepMask) {
    super();
    this.repMask = theRepMask;
    this.collectedParts = [];
  }

  /**
   * @param theRepMask
   */
  updateRepMask(theRepMask) {
    this.repMask = theRepMask;
    this.collectedParts.forEach(
      (theCollectedPart) =>
        (theCollectedPart.representation =
          theCollectedPart.sge.representation(theRepMask))
    );
  }

  /**
   * Clear the CustomSGEVisitor collected parts collection.
   */
  clear() {
    this.collectedParts.length = 0;
  }

  /**
   * Fill the CustomSGEVisitor collected parts collection by the data of the each part of model.
   */
  visitPart(thePart) {
    const aPartID = thePart.uuid ? thePart.uuid.toString() : "";
    /* Only unique parts (not all instances) should be present in the part selection drop-down list: */
    if (
      !this.collectedParts.find(
        (theCollectedPart) => theCollectedPart.sge === thePart
      )
    ) {
      this.collectedParts.push({
        id: aPartID,
        label: thePart.name || "Unnamed part",
        sge: thePart,
        representation: thePart.representation(this.repMask),
      });
    }
  }
}
